
/**
 * @name: 礼品卡管理-礼品卡使用规则
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 礼品卡管理-礼品卡使用规则
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
import { dictDetailApi, dictSetUpApi } from "@/apis/dict"
import type { ICrudOption, CrudColumn } from "@/types/m-ui-crud"

@Component({})
export default class cardRule extends Vue {
  // 表格加载状态
  loading: boolean = false;
  // 表单参数
  modelForm: any = {}
  // 表单配置项
  column: CrudColumn[] = [
    {
      label: "礼品卡使用规则",
      prop: "value",
      maxlength: 800,
      type: "textarea",
      showWordLimit: true,
      // @ts-ignore
      rows: 10,
      rules: [
        {
          required: true,
          message: "请输入礼品卡使用规则",
          trigger: "blur"
        }
      ],
      slot: true
    }
  ]

  getDetail () {
    this.loading = true
    dictDetailApi(1).then(e => {
      this.modelForm.value = e?.value || ''
    }).finally(() => {
      this.loading = false
    })
  }

  async updateDict () {
    // @ts-ignore
    const valid = await this.$refs.formRef.validForm()

    if (valid) {
      this.loading = true
      dictSetUpApi(1, this.modelForm.value).then(e => {
        if (e) {
          this.$message.success('操作成功!')
          this.getDetail()
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }

  created () {
    this.getDetail()
  }
}
